.App {
  //height: 100vh;
  display: grid;
  //justify-items: center;
  &__loading {
    display: grid;
    align-items: center;
    height: 50vh;
    font-size: 32px;
    font-weight: 600;
  }
}

.App__grid {
  display: grid;
  justify-self: center;
  grid-gap: 0.6%;
  margin: 2%;
}
